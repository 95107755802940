.work-container {
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.974);
    overflow: hidden;
}

#experience {
    margin: auto;
    padding: 50px 0;
    position: relative;
}

h3 {
    font-size: 24px;
    margin-bottom: 40px;
}



.experience-card {
    background-color: #ffffff13;
    border-left: 4px solid #725AC1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.experience-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.experience-card h5 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #e2e2e2;
}

.experience-card .text-primary {
    font-size: 0.9rem;
    font-weight: 500;
}

.experience-card p {
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
    line-height: 1.5;
}

.text-muted {
    color: #6c757d !important;
}