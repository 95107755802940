* {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
    color: white;
    position: relative;
    transition: all 0.5s ease;


}

.curser {
    background-color: rgba(255, 255, 255, 0.552);
    height: 26px;
    width: 26px;
    border-radius: 50%;
    box-shadow: 0px 2px 12px #35a2d2;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    transition: transform 0.1s ease-out;
}

.container {
    min-height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.974);
    overflow: hidden;
}

.sqaure-container {
    margin-left: 100px;
}

.square,
.square::before,
.square::after {
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    animation: rotate 10s linear infinite;
}

.square {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
    border-color: #5a4ff3;
}

.square::before,
.square::after {
    position: absolute;
    content: "";
}

.square::before {
    border-color: #35a2d2;
    width: 110%;
    height: 110%;
    animation-delay: .5s
}

.square::after {
    border-color: #9c40fc;
    width: 120%;
    height: 120%;
    animation-delay: .10s;
}

@keyframes rotate {
    to {
        transform: rotate(360deg)
    }
}

.sqaure-container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: end;
}


.nav-main-container {
    position: relative;
    z-index: 1000000;
    width: 100%;
}

.navbar-container {
    padding: 50px;
    display: flex;
    justify-content: space-between;
}

.logo {
    font-size: 50px;
    font-family: "Jolly Lodger", system-ui;
}

.menu-icon {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: end;
    cursor: pointer;

}

.menu-icon .bar {
    height: 2px;
    width: 80px;
    background-color: white;
    border-radius: 3px;
}

.menu-icon .bar:nth-child(2) {
    width: 60px;
}

@media (max-width: 768px) {
    .navbar-container {
        padding: 20px;
    }

    .menu-icon .bar {
        width: 60px;
    }

    .menu-icon .bar:nth-child(2) {
        width: 30px;
    }
}

.menu-container {
    width: 100%;
    background-color: rgb(232, 231, 231);
    position: absolute;
    top: 0;
    z-index: 1;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}

.menu-container.active {
    height: 100vh;
}

.close {
    display: flex;
    justify-content: center;
    font-size: 50px;
    color: #2f3545;
    margin: 20px 30px;
}

.close i {
    color: #2f3545;
}

.link-container {
    margin: 100px;
}

.link-container ul {
    display: flex;
    gap: 5px;
    flex: 1 1 auto;
    flex-direction: column;
}

.link-container ul li {
    list-style: none;
    border-bottom: 2px solid #2f3545;
    transition: all 0.3s ease-in;
}

.link-container ul li a {
    text-decoration: none;
    font-size: 60px;

    color: #2f3545;
    transition: all 0.5s ease-in;
}

.link-container ul li a:hover {
    color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: black;
}

.link-container ul li {
    transition: text-transform 0.3s ease, font-size 0.3s ease;
}

.link-container ul li:hover {
    text-transform: uppercase;
    font-size: 80px;
}

@media (max-width: 768px) {

    .link-container {
        margin: 10px;
    }

    .close {
        display: flex;
        justify-content: center;
        font-size: 50px;
        color: #2f3545;
        margin: 5px;
    }
}

main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    flex-direction: column;


}

main h1 {
    font-size: 4rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 2px 1px rgba(166, 206, 210, 1);
}

main h1 span {
    color: #5a4ff3de;
}

@media (max-width: 568px) {
    main {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 100px 20px;
    }

    main h1 {
        font-size: 3.3rem;
    }
}


.loader-container {
    height: 100vh;
    width: 100%;
    background-color: rgb(232, 231, 231);
    position: absolute;
    top: 0;
    z-index: 5;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 2s ease-out;
    /* Smooth transition */

}

.loader-container.fade-out {
    height: 0;
    /* Transition to height 0 */
}

.loader {
    --path: #2f3545;
    --dot: #5628ee;
    --duration: 3s;
    width: 44px;
    height: 44px;
    position: relative;
}

.loader:before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background: var(--dot);
    top: 37px;
    left: 19px;
    transform: translate(-18px, -18px);
    animation: dotRect var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg {
    display: block;
    width: 100%;
    height: 100%;
}

.loader svg rect,
.loader svg polygon,
.loader svg circle {
    fill: none;
    stroke: var(--path);
    stroke-width: 10px;
    stroke-linejoin: round;
    stroke-linecap: round;
}

.loader svg polygon {
    stroke-dasharray: 145 76 145 76;
    stroke-dashoffset: 0;
    animation: pathTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg rect {
    stroke-dasharray: 192 64 192 64;
    stroke-dashoffset: 0;
    animation: pathRect 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader svg circle {
    stroke-dasharray: 150 50 150 50;
    stroke-dashoffset: 75;
    animation: pathCircle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

.loader.triangle {
    width: 48px;
}

.loader.triangle:before {
    left: 21px;
    transform: translate(-10px, -18px);
    animation: dotTriangle var(--duration) cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
}

@keyframes pathTriangle {
    33% {
        stroke-dashoffset: 74;
    }

    66% {
        stroke-dashoffset: 147;
    }

    100% {
        stroke-dashoffset: 221;
    }
}

@keyframes dotTriangle {
    33% {
        transform: translate(0, 0);
    }

    66% {
        transform: translate(10px, -18px);
    }

    100% {
        transform: translate(-10px, -18px);
    }
}

@keyframes pathRect {
    25% {
        stroke-dashoffset: 64;
    }

    50% {
        stroke-dashoffset: 128;
    }

    75% {
        stroke-dashoffset: 192;
    }

    100% {
        stroke-dashoffset: 256;
    }
}

@keyframes dotRect {
    25% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(18px, -18px);
    }

    75% {
        transform: translate(0, -36px);
    }

    100% {
        transform: translate(-18px, -18px);
    }
}

@keyframes pathCircle {
    25% {
        stroke-dashoffset: 125;
    }

    50% {
        stroke-dashoffset: 175;
    }

    75% {
        stroke-dashoffset: 225;
    }

    100% {
        stroke-dashoffset: 275;
    }
}

.loader {
    display: inline-block;
    margin: 0 16px;
}


.social-media {
    display: flex;
    gap: 20px;
    margin: 30px 0;
}

.social-media a {
    font-size: 1.2rem;
    background-color: rgba(128, 128, 128, 0.629);
    padding: 5px 7px;
    border-radius: 3px;
    box-shadow: 1px 2px 1px rgba(166, 206, 210, 1);
    transition: all 0.5s ease;
}

.social-media a:hover {
    box-shadow: 1px 2px 0px rgb(199, 169, 15);
    color: yellow;
}

.social-media a i:hover {

    color: yellow;
}

.social-media a:hover+.icon {
    color: yellow;
}