* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;

}

body {
  width: 100%;
  max-width: 1920px;
  background: #725ac130;
  /* background: radial-gradient(circle, rgba(0, 0, 0, 0.708) 0%, rgba(50, 61, 88, 0.904) 0%, rgba(0, 0, 0, 0.923) 10%); */
  background:black;
}

.hero-section {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}



.nav-main-container {
  position: relative;
  z-index: 1000000;
  width: 100%;
}

.navbar-container {
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

.logo {
  font-size: 50px;
  font-family: "Jolly Lodger", system-ui;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: end;
  cursor: pointer;

}

.menu-icon .bar {
  height: 2px;
  width: 80px;
  background-color: white;
  border-radius: 3px;
}

.menu-icon .bar:nth-child(2) {
  width: 60px;
}

.heading {
  font-size: 5rem;
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 2px 12px rgba(166, 206, 210, 1);
}

.input {
  color: #C0EEF2;
  font-size: 5rem;
}

main {
  text-align: center;
  position: relative;
  width: 70%;
}


.para {
  font-weight: bold;
  color: #e9f8f9be;
}

.scroll-icon {
  color: #C0EEF2;
  position: absolute;
  bottom: 10px;
}

.social-icons {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 4;
  gap: 20px;
}

.social-icons i {
  position: relative;
  color: #C0EEF2;
  font-size: 1.6rem;
  transition: all 0.3 ease-in-out;
}

.social-icons i:hover {
  color: #725AC1;
}

.mobile-visiblity {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: darken;
  opacity: 0.6;
  z-index: 1;
}

#about,
#experience {
  height: 100%;
  width: 100%;
  padding-top: 100px;
  /* background: radial-gradient(circle, rgba(0, 0, 0, 0.708) 0%, rgba(50, 61, 88, 0.904) 0%, rgba(0, 0, 0, 0.923) 10%); */
  color: #C0EEF2;
  display: flex;
  align-items: center;
  flex-direction: column;
}

h3 {
  margin: 0 5%;
  font-size: 2rem;
  text-shadow: 3px 4px 18px rgba(166, 206, 210, 1);
  text-align: center;
}

.about-head {
  text-align: center;
  padding: 14px 0;
  margin: 0 18%;
  opacity: 0.7;
}

.about-container {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: row;
}

.about-left,
.about-right {
  width: 50%;

}

.left-content {
  margin: 10%;
  height: 100%;
}

.left-content h4,
.right-content h4 {
  font-size: 32px;
  font-weight: bold;
  text-shadow: 1px 2px 12px rgba(166, 206, 210, 0.313);
}

.left-content p,
.right-content p {
  margin: 10px 0;
  font-size: 21px;
  font-weight: 500;
  text-align: justify;
  height: 100%;
}

.experience p {
  margin: 10px 0;
  font-size: 21px;
  font-weight: 500;
  text-align: cetner;

}

.right-content {
  margin: 10%;
}

.right-content div {
  display: inline-block;
  padding: 5px;
  gap: 5px;
}

.right-content div span {

  padding: 7px 14px;
  gap: 10px;
  border-radius: 5px;
  background: #D9D9D9;
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 30px;
  display: inline-block;
  margin: 5px;
  box-shadow: rgb(225, 225, 225) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#projects {
  height: 100%;
  width: 100%;

  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.project-container h4 {
  font-size: 22px;
  border-bottom: 10%;
  width: 100%;
  text-align: center;

}

.card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

}

.card {
  position: relative;
  width: 400px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(232, 228, 228, 0.07) 0px 1px 2px, rgba(255, 255, 255, 0.07) 0px 2px 4px, rgba(232, 232, 232, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(236, 231, 231, 0.216)0px 32px 64px;
  cursor: pointer;
  flex-grow: 1;
}

.text>span {
  display: flex;
  gap: 10px;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000091;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.card:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  position: absolute;
  margin: 29px;


}

.text p {
  color: #C0EEF2;
  font-weight: 700;
}

.demo-btn {
  position: relative;
  display: inline-block;
  margin: 15px 0;
  padding: 10px 30px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #C0EEF2;
  background: transparent;
  cursor: pointer;
  transition: ease-out 0.5s;
  border: 2px solid #725AC1;
  border-radius: 10px;
  box-shadow: inset 0 0 0 0 #725AC1;
}

.demo-btn:hover {
  color: white;
  box-shadow: inset 0 -100px 0 0 #725AC1;
}

.demo-btn:active {
  transform: scale(0.9);
}

#contact {
  height: 100%;

  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
}

.form-container {
  height: 100%;
  width: 70%;
}

form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.name,
.email {
  display: flex;
  flex-direction: column;

}

label {
  margin: 10px 0;
}

.name input,
.email input {
  width: 300px;
  padding: 10px;
  background-color: #f5f2f26d;
  border: none;
  border-radius: 7px;
}

.message-box {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.message-box textarea {
  width: 640px;
  padding: 10px;
  background-color: #f5f2f26d;
  border: none;
  border-radius: 7px;

}

.submitbtn {
  background-color: #725AC1;
  padding: 10px 40px;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 1rem;
  border: none;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submitbtn:hover {
  background-color: transparent;
  outline: 3px solid #725AC1;
}

#desktop-nav {
  display: block;
}

#mobile-nav {
  display: none;
}


#contact .social-icons {
  margin: 0;
  padding: 50px 0;
}

@media(max-width:900px) {
  #desktop-nav {
    display: none;
  }

  #mobile-nav {
    display: block;
  }

  main {
    width: 90%;
    display: flex;
    flex-direction: column;

  }

  .heading {
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
    text-align: start;
  }

  .input {
    color: #C0EEF2;
    font-size: 2.2rem;
  }

  .star-1 {
    position: absolute;
    right: 20px;

    top: 100px;
  }

  .star-2 {
    position: absolute;
    left: 20px;
    bottom: 200px;

  }

  a {
    display: block;
    padding: 8px 22px;
    color: #E9F8F9;
    font-size: 12px;
    text-decoration: none;
    border-radius: 30px;
    transition: background-color 0.3s;
  }

  .para {

    text-align: start;
  }

  .mobile-visiblity {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: multiply;
    opacity: 0.6;
    height: 100%;
    width: 100%;
  }



  .about-head,
  h3 {
    margin: 0 2%;
    text-align: center;
  }

  .about-container {
    flex-direction: column;
  }

  .about-left,
  .about-right {
    width: 100%;

  }

  .right-content,
  .left-content {
    margin: 2%;
  }

  .left-content h4,
  .right-content h4,
  .experience h4 {
    font-size: 22px;
    font-weight: bold;
    text-shadow: 1px 2px 12px rgba(166, 206, 210, 1);
    text-align: center;
  }

  .left-content p,
  .right-content p {
    margin: 10px 0;
    font-size: 16px;
    font-weight: 500;
    text-align: justify;
    height: 100%;
  }

  .right-content div span {

    padding: 5px 12px;
    gap: 10px;
    border-radius: 5px;
    background: #D9D9D9;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 30px;
    display: inline-block;
    margin: 5px;
    box-shadow: rgb(225, 225, 225) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .demo-btn {
    position: relative;
    display: inline-block;
    margin: 5px 0;
    padding: 8px 20px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    color: #C0EEF2;
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid #725AC1;
    border-radius: 10px;
    box-shadow: inset 0 0 0 0 #725AC1;
  }

  .form-container {
    height: 100%;
    width: 100%;
  }

  form {

    width: 100vw;
  }

  .message-box textarea {
    width: 90vw;
  }

  .name input,
  .email input {
    width: 90vw;
  }

  .input-box {

    flex-direction: column;

  }

  .card-container {
    width: 100%;
  }

}


@media (max-width:768px) {
  .card-container{
    overflow: hidden;
  }
  .card {
    flex-grow: 1;

  }
}